<template>
  <v-app-bar class="app-bar-header d-flex align-center" app elevate-on-scroll>
    <!-- This is the mobile hamburger menu which is temporarilty disabled for demo
        but we can keep the code for future use.
  -->

    <!-- <v-menu
      offset-y
      transition="slide-y-transition"
      close-on-click
      min-width="100%"
      nudge-bottom="9"
      z-index="0"
      v-if="this.$vuetify.breakpoint.xsOnly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon absolute v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
      </template>
      <v-list>
        <v-list-item @click="toLogin"> Sign in </v-list-item>
      </v-list>
    </v-menu> -->
    <v-container class="reg-container reg-header__container py-0">
      <v-row no-gutters>
        <v-col
          v-if="this.$vuetify.breakpoint.smAndUp && showBackButton"
          class="d-flex align-center"
        >
          <v-btn text @click="goBack" :disabled="backDisabled"
            ><v-icon>mdi-chevron-left</v-icon><span>{{ $t('main.back') }}</span></v-btn
          >
        </v-col>
        <div class="back-button-mobile" v-if="this.$vuetify.breakpoint.xsOnly && showBackButton">
          <v-btn text @click="goBack" :disabled="backDisabled"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
        </div>
        <v-col class="logo-image d-flex" :class="logoAlignment">
          <logo-image
            :mode="mode"
            :height="logoHeight"
            :prefix="$route.params.id || $route.params.tenantUrl"
          ></logo-image>
        </v-col>
        <!-- <v-col v-if="!hideSignIn" class="logo-image d-flex align-center justify-end" align="end">
          <div class="d-flex justify-end" v-if="this.tenantUrl !== null">
            <hover-button @click="toLogin"> Sign In </hover-button>
          </div>
        </v-col> -->
      </v-row>
    </v-container>

    <!-- <v-container v-if="this.$vuetify.breakpoint.xsOnly" class="reg-container">
      <v-row no-gutters>
        <v-col class="logo-image d-flex justify-center" @click="toStart">
          <logo-image></logo-image>
        </v-col>
      </v-row> -->
    <!-- </v-container> -->
  </v-app-bar>
</template>
<style lang="scss">
.app-bar-header {
  z-index: 10;
  height: 107px !important;
  background-color: var(--v-background-base);
  .v-toolbar__content {
    flex-grow: 1 !important;
    height: inherit !important;
    padding: 0px 16px;
  }
}
.back-button-mobile {
  position: fixed;
  left: 0;
  top: 20px;
  z-index: 11;
}

.logo-image > img {
  height: 100%;
}
.mobile-header-container {
  height: 100% !important;
  width: unset;
  z-index: 10;
  margin: auto;
}
.reg-header__container {
  height: inherit;
  .row {
    height: inherit;
    .logo-image {
      position: relative;
      height: inherit;
      cursor: pointer;
    }
  }
}
.tab-desktop {
  min-width: 10em !important;
  cursor: default;
}
.sign-in-mobile {
  top: 70px !important;
  width: 100% !important;
}
.v-tab:hover:before,
.v-tab:focus:before {
  color: transparent;
}
.v-stepper {
  box-shadow: none;
}

@media all and (max-width: 960px) {
  .app-bar-header {
    height: 70px !important;
  }
}
</style>
<script>
import LogoImage from './LogoImage.vue';
/* eslint-disable global-require */
export default {
  name: 'RegisterHeader',
  components: {
    LogoImage,
  },
  props: ['elevate', 'hideSignIn'],
  data() {
    return {
      backDisabled: false,
      showSignIn: false,
    };
  },
  methods: {
    async goBack() {
      this.backDisabled = true;
      await this.$store.commit('goBackHeader');
      this.backDisabled = false;
    },
    toLogin() {
      this.$router.push({
        name: 'Login',
        params: { tenantUrl: this.$store.state.registration.tenantUrl },
      });
    },
  },
  computed: {
    logoAlignment() {
      if (this.$vuetify.breakpoint.smAndDown) return 'justify-center';
      return this.showBackButton ? 'justify-end' : 'justify-start';
    },
    logoHeight() {
      return this.$vuetify.breakpoint.smAndDown ? '70px' : '107px';
    },
    mode() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light';
    },
    pageOptions() {
      return this.$store.state.registration.currentPage?.options;
    },
    showBackButton() {
      return !!this.pageOptions?.backButtonHeader;
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
};
</script>
